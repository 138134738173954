let isPopupOpen = false;
export default function OpenPopup(postID) {
    if (isPopupOpen) {
        return;
    }
    const renderPopup = async () => {
        isPopupOpen = true;
        const { url } = ajax;
        const formData = new FormData();
        formData.append('action', 'mda_open_popup');
        formData.append('postID', postID);
        try {
            const req = await fetch(url, {
                method: 'POST',
                body: formData,
            });
            const response = await req.text();
            const popupElement = document.createElement('div');
            popupElement.classList.add('popup');
            popupElement.innerHTML = response;
            document.body.appendChild(popupElement);
            setTimeout(() => {
                popupElement.classList.add('active');
            }, 300);
        } catch (error) {
            console.log(error);
        } finally {
            isPopupOpen = false;
        }

        if (document.querySelector('.popup__card__close')) {
            document
                .querySelector('.popup__card__close')
                .addEventListener('click', () => {
                    closePopup();
                });
        }
    };

    renderPopup();

    const closePopup = () => {
        const popupElement = document.querySelector('.popup');
        popupElement.classList.remove('active');
        if (popupElement) {
            setTimeout(() => {
                popupElement.parentNode.removeChild(popupElement);
            }, 500);
        }
    };

    document.addEventListener('click', function (event) {
        const popup = document.querySelector('.popup__card');
        if (popup && !popup.contains(event.target)) {
            closePopup();
        }
    });
}
